import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { Layout } from "../components";
import Block from "../components/cta.js";
import VizPage from "../components/vizPage.js";
import Logo from "../blocks/logo";
import McwSvg from '../components/MCW-SVG';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Layout mdxType="Layout">
      <VizPage heading="About This Project" mdxType="VizPage">
        <h1>Overview: </h1>
        <br></br>
        <p>{`Matt Desmond’s research and years of ethnographic fieldwork in Milwaukee made the case that eviction is not just a symptom of poverty, but also a cause. Due to his work, there is a renewed nationwide and local focus on eviction and housing policy in general. Here in Milwaukee, the Wisconsin Policy Forum and now UEDA have convened a Milwaukee Eviction Prevention coalition to focus on effective ways to prevent eviction.`}</p>
        <br></br>
        <p>{`Despite the heightened focus on eviction in Milwaukee policymakers, researchers, and community partners lack access to eviction data to inform prevention strategies, identify and monitor trends, or conduct eviction related research. To that end, researchers at the Medical College of Wisconsin Institute for Health Equity’s Division of Epidemiology partnered with Legal Action of Wisconsin, the Milwaukee City Attorney’s Office, and the Milwaukee Department of Neighborhood Services.`}</p>
        <br></br>
        <p>{`This partnership has resulted in:`}</p>
        <br></br>
        <li>
1. An automated pipeline that maintains a database of Milwaukee eviction records, geocodes and matches eviction filings to their parcel Tax ID, and generates key metrics (e.g. % default judgement). Data is updated weekly.
        </li>
        <br></br>
        <li>
2. Series of visualizations on key eviction metrics and case outcomes that update on monthly and yearly intervals.
        </li>
        <br></br>
        <li>
3. Detailed parcel level map of eviction filings that updates monthly. This work is typically only done in large cities with dedicated technical staff, for example New York City, or with strong civic tech scenes like the Anti-Eviction Mapping Project. Other parcel level maps fail to update and soon become outdated. See ProPublica’s map tracking rent stabilization and evictions.
        </li>
        <br></br>
        <li>
4. Visualization tool that identifies eviction records with building code violations issued within 90 days as well as open at the time of filing.
        </li>
        <br></br>
        <p>{`Thanks goes to ProPublica whose work matching eviction records to building parcels for their Rent Stabilization and Eviction Map served as a major inspiration. Additionally, the Eviction Lab’s Methods Report detailing their approach to match an eviction record to an address was an invaluable resource.`}</p>
        <br></br>
        <p>{`Thanks also to City Staff at the Department of Neighborhood Services and the City Attorney’s Office as well as Legal Action of Wisconsin. In addition to financing this project, their time and feedback has been essential.`}</p>
        <hr></hr>
        <Logo mdxType="Logo"></Logo>
      </VizPage>
    </Layout>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      